import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  active?: boolean
  category: string
  onClick?: (e: any) => void
  title: string
}

export const Filter = memo(function Filter({
  active = false,
  category,
  onClick,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container
      className={active ? 'active' : undefined}
      data-category={category}
      onClick={onClick}
    >
      {title}
    </Container>
  )
})

const Container = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 2rem;
  margin: 0 1.875rem;
  position: relative;
  transition: 0.2s ease-in-out;
  &:after {
    content: '';
    width: 100%;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.variants.primaryLight2};
  }
  &.active {
    pointer-events: none;
    &:after {
      opacity: 1;
    }
  }
`
