import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { Close } from 'app/components/Common/Modal/Close'
import { FlexBox } from 'app/components/Layout/FlexBox'
import useLockedBody from 'app/utils/useLockedBody'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Filter } from './Filter'
import { Gallery } from './Gallery'

export interface CategoryProps {
  thumbnails?: ImageProps[]
  title: string
}

export interface Props {
  categories: CategoryProps[]
  label?: string
  languageCode: string
  siteName?: string
}

export const GalleryCategories = memo(function GalleryCategories({
  categories,
  label,
  languageCode,
  siteName,
}: Props) {
  if (!categories) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [filteredCategory, setFilteredCategory] = useState('all')
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  let counter = 0
  let images: any = []

  const categoriesImages = categories.map((item: any) =>
    item.images.map((e: any) => images.push(e)),
  )

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      <Head>
        {label ? <Label>{label}</Label> : null}
        {siteName ? <SiteName>{siteName}</SiteName> : null}
        <Line className="line" />
        {categories.length > 1 ? (
          <Filters dial={5} row wrap>
            <Filter
              active={filteredCategory === 'all'}
              category="all"
              title={useVocabularyData('all', languageCode)}
              onClick={() => {
                setFilteredCategory('all')
              }}
            />
            {uniqBy(categories, 'title').map((item, index) => {
              const category = item.title
                .replace(/[^a-zA-Z]/g, '')
                .toLocaleLowerCase()

              return category ? (
                <Filter
                  active={filteredCategory === category}
                  category={category}
                  key={index}
                  onClick={(e) => {
                    setFilteredCategory(e.target.getAttribute('data-category'))
                  }}
                  title={item.title}
                />
              ) : undefined
            })}
          </Filters>
        ) : null}
      </Head>

      <Categories row wrap>
        {uniqBy(categories, 'title').map((list) => {
          const category = list.title
            .replace(/[^a-zA-Z]/g, '')
            .toLocaleLowerCase()

          if (!list.thumbnails) {
            return null
          }

          return list.thumbnails.map((item, index) => {
            counter = counter + 1

            return (
              <Thumb
                className={
                  filteredCategory === category || filteredCategory === 'all'
                    ? 'visible'
                    : undefined
                }
                data-counter={counter}
                key={index}
                onClick={(e) => {
                  if (!modalGalleryStatus) {
                    setLocked(true)
                    setModalGalleryStatus(true)
                  } else {
                    setLocked(false)
                    setModalGalleryStatus(false)
                  }

                  const realClickedSlide =
                    Number(e.currentTarget.getAttribute('data-counter')) - 1

                  setClickedSlide(realClickedSlide)
                  setTimeout(() => {
                    setClickedSlide(null)
                  }, 100)
                }}
              >
                <LazyLoadComponent>
                  <Image {...item} />
                </LazyLoadComponent>
              </Thumb>
            )
          })
        })}
      </Categories>

      <Modal className={modalGalleryStatus ? ' open' : undefined}>
        <Close
          onClick={() => {
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
          }}
        />
        <Gallery
          clickedSlide={clickedSlide}
          images={images}
          isOpen={modalGalleryStatus}
        />
      </Modal>
    </Container>
  )
})

const Container = styled.section`
  margin: 14.875rem auto 9.375rem;
  padding: 0 7.292vw;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 11.25rem auto 5.625rem;
    padding: 0 1.875rem;
  }

  @media (max-width: 1023px) {
    margin-top: 9.375rem;
  }
`

const Head = styled.div`
  .line {
    margin-left: auto;
    margin-right: auto;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.125rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`

const SiteName = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.875rem;

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`

const Filters = styled(FlexBox)`
  margin-top: 4.625rem;

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Categories = styled(FlexBox)`
  margin: 2.625rem -1.875rem 0 0;

  @media (max-width: 1199px) {
    margin-top: 3.75rem;
  }

  @media (max-width: 1023px) {
    margin: 2.5rem -0.75rem 0 0;
  }
`

const Thumb = styled.div`
  display: none;
  width: calc(25% - 1.875rem);
  cursor: pointer;
  overflow: hidden;
  margin: 1.875rem 1.875rem 0 0;
  padding-bottom: calc(25% - 1.875rem);
  position: relative;
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  &.visible {
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out !important;
  }

  @media (max-width: 1023px) {
    width: calc(25% - 0.75rem);
    margin: 0.75rem 0.75rem 0 0;
    padding-bottom: calc(25% - 0.75rem);
  }

  @media (max-width: 767px) {
    width: calc(50% - 0.75rem);
    padding-bottom: calc(50% - 0.75rem);
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`
