import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  clickedSlide?: number
  images: ImageProps[]
  isOpen?: boolean
}

export const Gallery = memo(function Gallery({
  clickedSlide,
  images,
  isOpen,
}: Props) {
  if (!images) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [currentCategory, setCurrentCategory] = useState<any>(
    (images[0] as any)?.category,
  )
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (galleryRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1200,
    },
    loop: images.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
      setCurrentCategory((images[s.track.details.rel] as any).category)
    },
    detailsChanged(s) {
      images.length > 1 ? setDetails(s.track.details) : null
    },
    breakpoints: {
      '(max-width: 767px)': {
        drag: true,
      },
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    if (clickedSlide !== undefined) {
      galleryRef.current?.moveToIdx(clickedSlide, false, { duration: 0 })
    }

    setLoaded(newLoaded)

    if (isOpen) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 37:
            galleryRef.current?.prev()
            break
          case 39:
            galleryRef.current?.next()
            break
        }
      })
    }
  }, [clickedSlide, currentSlide, galleryRef, sliderRef])

  return (
    <Container>
      <Carousel ref={sliderRef}>
        {uniq(images).map((item, index) => (
          <Slide key={index} className="keen-slider__slide">
            <Inner style={positionStyle(index)}>
              {loaded[index] ? (
                <Image media="(min-width: 2800px)" {...item} />
              ) : (
                <Spinner />
              )}
            </Inner>
          </Slide>
        ))}
      </Carousel>

      <Info dial={4} row space="between">
        <Category>{currentCategory}</Category>
        {images && images.length > 1 ? (
          <Arrows row>
            <Arrow
              onClick={(e) => e.stopPropagation() || galleryRef.current?.prev()}
              variant="small"
            />
            <Arrow
              direction="R"
              onClick={(e) => e.stopPropagation() || galleryRef.current?.next()}
              variant="small"
            />
          </Arrows>
        ) : null}
        <Counter>{`${currentSlide + 1}/${images.length}`}</Counter>
      </Info>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`

const Carousel = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 7.5rem);
  outline: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 1199px) {
    height: calc(100% - 5rem);
  }
`

const Slide = styled.div`
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 767px) {
    img {
      height: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Info = styled(FlexBox)`
  width: 100%;
  padding: 2.625rem 7.292vw;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;

  @media (max-width: 1199px) {
    padding: 1.375rem 1.875rem;
  }
`

const Category = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.25rem;

  @media (max-width: 1199px) {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > div {
    background: none;
  }

  @media (max-width: 767px) {
    left: 1.125rem;
    transform: translateY(-50%);
  }
`

const Counter = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 300;
  line-height: 2rem;

  @media (max-width: 767px) {
    margin-left: auto;
  }
`
